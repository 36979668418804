import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute.jsx";

import routes from "./routes.js";
import Preloader from "@components-shared/Preloader/index.js";

const Login = lazy(() => import("@components-pages/Login"));

const TestList = lazy(() => import("@components-pages/TestList"));
const TestCreate = lazy(() => import("@components-pages/TestCreate"));
const TestEdit = lazy(() => import("@components-pages/TestEdit"));

const QuestionList = lazy(() => import("@components-pages/QuestionList"));
const QuestionCreate = lazy(() => import("@components-pages/QuestionCreate"));

const InstructionList = lazy(() => import("@components-pages/InstructionList"));

const InstructionCreate = lazy(() => import("@components-pages/InstructionCreate"));
const PublishedTests = lazy(() => import("@components-pages/PublishedTests"));
const TestPublications = lazy(() => import("@components-pages/TestPublications"));
const TestAttempts = lazy(() => import("@components-pages/TestAttempts"));
const AttemptResponses = lazy(() => import("@components-pages/AttemptResponses"));
const UserProfile = lazy(() => import("@components-pages/UserProfile"));
const UserList = lazy(() => import("@components-pages/UserList"));
const QuestionEdit = lazy(() => import("@components-pages/QuestionEdit"));
const FilterList = lazy(() => import("@components-pages/FilterList"));
const SubjectList = lazy(() => import("@components-pages/SubjectList"));

const ROUTES_LIST = [
  { path: routes.Login, element: <Login /> },
  {
    path: routes.TestList,
    element: <ProtectedRoute element={<TestList />} />,
  },
  {
    path: routes.TestCreate,
    element: <ProtectedRoute element={<TestCreate />} />,
  },
  {
    path: `${routes.TestList}/:testId`,
    element: <ProtectedRoute element={<TestEdit />} />,
  },
  {
    path: routes.QuestionList,
    element: <ProtectedRoute element={<QuestionList />} />,
  },
  {
    path: routes.QuestionCreate,
    element: <ProtectedRoute element={<QuestionCreate />} />,
  },
  {
    path: routes.InstructionList,
    element: <ProtectedRoute element={<InstructionList />} />,
  },
  {
    path: routes.InstructionCreate,
    element: <ProtectedRoute element={<InstructionCreate />} />,
  },
  {
    path: routes.PublishedTests,
    element: <ProtectedRoute element={<PublishedTests />} />,
  },
  {
    path: routes.PublishedTests,
    element: <ProtectedRoute element={<PublishedTests />} />,
  },
  {
    path: `${routes.TestPublications}/:testId`,
    element: <ProtectedRoute element={<TestPublications />} />,
  },
  {
    path: `${routes.TestAttempts}/:publicationId`,
    element: <ProtectedRoute element={<TestAttempts />} />,
  },
  {
    path: `${routes.AttemptResponses}/:testAttemptId`,
    element: <ProtectedRoute element={<AttemptResponses />} />,
  },
  { path: `${routes.QuestionList}/:questionId`, element: <ProtectedRoute element={<QuestionEdit />} /> },
  {
    path: `${routes.Users}`,
    element: <ProtectedRoute element={<UserList />} />,
  },
  {
    path: `${routes.Users}/:userId`,
    element: <ProtectedRoute element={<UserProfile />} />,
  },
  {
    path: `${routes.Filters}`,
    element: <ProtectedRoute element={<FilterList />} />,
  },
  {
    path: `${routes.SubjectList}`,
    element: <ProtectedRoute element={<SubjectList />} />,
  },
];

export default function AppRouter() {
  return (
    <Suspense
      fallback={
        <div>
          <Preloader />
        </div>
      }
    >
      <Routes>
        {ROUTES_LIST.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}

        <Route path="/" element={<Navigate to={routes.TestList} />} />
      </Routes>
    </Suspense>
  );
}

import React from "react";
import style from "./Preloader.module.scss";

import { ClipLoader } from "react-spinners";

export default function Preloader() {
  return (
    <div className={style.wrapper}>
      <ClipLoader />
    </div>
  );
}
